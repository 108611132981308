import React, { useEffect } from 'react'

import { RouteComponentProps } from '@reach/router'
import 'twin.macro'

import CalendlyInlineScheduler from 'components/shared/meeting/calendly-inline-scheduler'
import SEO from 'components/shared/seo'
import fireEvent from 'context/tracking/events'
import Layout from 'mx/layout'
import { MeetingTypeProspect } from 'types/meeting'

export interface UserState {
  email: string
  firstName: string
  lastName: string
  phone: string
}

const VisitSanFranciscoPage = (
  props: RouteComponentProps<{
    location?: {
      state?: UserState
    }
  }>,
) => {
  const { state } = props.location || {}
  const { email = '', firstName = '', lastName = '', phone = '' } = state || {}

  useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [])

  return (
    <Layout>
      <CalendlyInlineScheduler
        calendlyUrl={process.env.GATSBY_CALENDLY_HOMEPAGE_URL_SF ?? ''}
        user={{
          email,
          firstName,
          lastName,
          phone,
        }}
        onEventScheduled={() => {
          fireEvent({
            type: 'schedule_completed',
            email: email,
            firstName: firstName,
            lastName: lastName,
            meetingType: 'SELECTION_CENTER',
            phone: phone,
          })
        }}
        type={MeetingTypeProspect.SELECTION_CENTER}
      />
      <br />
      <br />
    </Layout>
  )
}

export default VisitSanFranciscoPage

export const Head = () => (
  <SEO
    title="Visit Our SF Studio"
    description="Visit our San Francisco studio to experience our modern German cabinetry and start your project. Book a visit today"
    path="/book-san-francisco"
  />
)
